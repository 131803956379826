import jsonData from "../json/data.json";

function retrieveProductByCategoryAndProductName(productCategory, productName) {
  console.log(productCategory, productName);
  try {
    let categoryProducts = retrieveProductsByCategoryName(productCategory)
    console.log(categoryProducts)
    return categoryProducts.find(el => el.productTitle.toLowerCase() === productName.toLowerCase())
  } catch (error) {
    return [];
  }
}

function retrieveProductsByCategoryName(productCategory) {
  try {
    return jsonData[productCategory]
  } catch (error) {
    console.error("Product not found");
    return [];
  }
}

function retrieveProductCategories(){
  try {
    return Object.keys(jsonData)
  } catch (error) {
    return []
  }
}

export {
  retrieveProductByCategoryAndProductName, 
  retrieveProductCategories,
  retrieveProductsByCategoryName
}