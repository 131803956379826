import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { Link as Rlink } from "react-router-dom";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import {retrieveProductCategories} from "../../utils/productParser"

const pages = [
{ text: "ABOUT", href: "about-us" },
// { text: "PRODUCTS", href: "" },

{ text: "SOLAR POWER", href: "" },
{ text: "CONTACT US", href: "contact-us" },
];

const DropDownBox = styled.div`
position: relative;
&:hover div {
    visibility: visible;
}
`;

const DropDown = styled.div`
position: absolute;
background: #fff;
padding: 10px;
width: 215px;
top: 0;
left: 0;
transform: translate(0%, 54px);
visibility: hidden;
display: flex;
flex-direction: column;
a {
    padding: 10px;
    color: #07bc0c;
    &:hover {
    color: white;
    background-color: #07bc0c;
    }
}
`;

const CountrySelectorBox = styled(Box)`
background-color: #f0f0f0;  // Light gray background
border-radius: 4px;
display: flex;
align-items: center;
justify-content: center;
padding: 4px 12px;
cursor: pointer;
&:hover {
    background-color: #ddd;
}
a {
    color: black;
    text-decoration: none;
    margin: 0 8px;
    font-weight: 500;
    &:hover {
    color: #07bc0c;  // Highlight on hover
    }
}
`;

function NavBar({ handleNavClick }) {
const location = useLocation();
const currentPath = location.pathname;

const [anchorElNav, setAnchorElNav] = React.useState(null);

const productCategories = retrieveProductCategories()

const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
};

const handleCloseNavMenu = () => {
    setAnchorElNav(null);
};

return (
    <AppBar
    position="static"
    sx={{
        background: "transparent",
        mt: 0.5,
        diplay: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    }}
    >
    <Container
        sx={{
        padding: 0,
        margin: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",  // Ensure the container spans the entire width
        maxWidth: "1200px"
        }}
    >
        <Toolbar disableGutters sx={{ padding: "0 16px", flexGrow: 1 }}>
        <Rlink to={"/"}>
            <Box
            component="img"
            sx={{
                width: 120,
                display: { xs: "none", md: "flex" },
                mr: 1,
                objectFit: "cover"
            }}
            alt="GREENWATT"
            src="/greenwatt-transparent.png"
            />
        </Rlink>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
            >
            <MenuIcon sx={{ color: "black" }} />
            </IconButton>
            <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
                display: { xs: "flex", md: "none" },
            }}
            >
            {pages.map((page, i) => (
                <MenuItem
                key={i}
                onClick={handleCloseNavMenu}
                style={{ background: "black" }}
                >
                {page.text === "PRODUCTS" ? (
                    <div>
                    <Rlink
                        onClick={(e) => {
                        if (currentPath === "/") {
                            handleNavClick(e.target.href);
                        }
                        }}
                        to={currentPath === "/" ? page.href : `/${page.href}`}
                        style={{
                        color: "white",
                        display: "flex",
                        textDecoration: "none",
                        fontWeight: 500,
                        }}
                    >
                        {page.text}
                        <span
                        style={{
                            transform: "rotate(90deg) scaleY(1.5)",
                            marginLeft: "8px",
                        }}
                        >
                        {">"}
                        </span>
                    </Rlink>

                    <div
                        style={{
                        color: "white",
                        marginLeft: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        }}
                    >
                        <Rlink
                        to={`/solar-panel`}
                        style={{
                            textTransform: "uppercase",
                            color: "white",
                            textAlign: "left",
                            textDecoration: "none",
                        }}
                        >
                        Jinko
                        </Rlink>
                        <Rlink
                        to={`/solar-panel`}
                        style={{
                            textTransform: "uppercase",
                            color: "white",
                            textAlign: "left",
                            textDecoration: "none",
                        }}
                        >
                        Trina
                        </Rlink>
                        <Rlink
                        to={`/solar-panel`}
                        style={{
                            textTransform: "uppercase",
                            color: "white",
                            textAlign: "left",
                            textDecoration: "none",
                        }}
                        >
                        Longi
                        </Rlink>
                        <Rlink
                        to={`/solar-panel`}
                        style={{
                            textTransform: "uppercase",
                            color: "white",
                            textAlign: "left",
                            textDecoration: "none",
                        }}
                        >
                        Greenwatt
                        </Rlink>
                    </div>
                    </div>
                ) : page.text === "SOLAR POWER" ? (
                    <div>
                    <Rlink
                        onClick={(e) => {
                        if (currentPath === "/") {
                            handleNavClick(e.target.href);
                        }
                        }}
                        to={currentPath === "/" ? page.href : `/${page.href}`}
                        style={{
                        color: "white",
                        display: "flex",
                        textDecoration: "none",
                        fontWeight: 500,
                        }}
                    >
                        {page.text}
                        <span
                        style={{
                            transform: "rotate(90deg) scaleY(1.5)",
                            marginLeft: "8px",
                        }}
                        >
                        {">"}
                        </span>
                    </Rlink>

                    <div
                        style={{
                        color: "white",
                        marginLeft: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        }}
                    >
                        <Rlink
                        to={`/ppa`}
                        style={{
                            textTransform: "uppercase",
                            color: "white",
                            textAlign: "left",
                            textDecoration: "none",
                        }}
                        >
                        Power Purchase Agreement
                        </Rlink>
                        {/* <Rlink
                        to={`/turnkey-solutions`}
                        style={{
                            textTransform: "uppercase",
                            color: "white",
                            textAlign: "left",
                            textDecoration: "none",
                        }}
                        >
                        Turnkey Solutions
                        </Rlink> */}
                    </div>
                    </div>
                ) : (
                    <Rlink
                    to={currentPath === "/" ? page.href : `/${page.href}`}
                    style={{
                        color: "white",
                        display: "flex",
                        textDecoration: "none",
                        fontWeight: 500,
                    }}
                    >
                    {page.text}
                    </Rlink>
                )}
                </MenuItem>
            ))}
            </Menu>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, i) => (
            <DropDownBox key={i}>
                <Rlink
                to={currentPath === "/" ? page.href : `/${page.href}`}
                target={page?.target}
                style={{
                    margin: "16px",
                    color: "black",
                    display: "flex",
                    textDecoration: "none",
                    fontWeight: 500,
                }}
                >
                {page.text}
                {page.text === "PRODUCTS" && (
                    <span
                    style={{
                        transform: "rotate(90deg) scaleY(1.5)",
                        marginLeft: "8px",
                    }}
                    >
                    {">"}
                    </span>
                )}
                </Rlink>
                {page.text === "PRODUCTS" && (
                <DropDown>
                    {productCategories.map((category)=>{
                        return(
                            <Rlink
                                to={`/product/${category}`}
                                style={{
                                    textTransform: "uppercase",
                                    textDecoration: "none",
                                    textAlign: "left",
                                }}
                            >
                                {category}
                            </Rlink>
                        )
                    })}
                    {/* <Rlink
                    to={`/product/jinko`}
                    style={{
                        textTransform: "uppercase",
                        textDecoration: "none",
                        textAlign: "left",
                    }}
                    >
                    Jinko
                    </Rlink>
                    <Rlink
                    to={`/solar-panel`}
                    style={{
                        textTransform: "uppercase",
                        textDecoration: "none",
                        textAlign: "left",
                    }}
                    >
                    Trina
                    </Rlink>
                    <Rlink
                    to={`/solar-panel`}
                    style={{
                        textTransform: "uppercase",
                        textDecoration: "none",
                        textAlign: "left",
                    }}
                    >
                    Longi
                    </Rlink>
                    <Rlink
                    to={`/solar-panel`}
                    style={{
                        textTransform: "uppercase",
                        textDecoration: "none",
                        textAlign: "left",
                    }}
                    >
                    Greenwatt
                    </Rlink> */}
                </DropDown>
                )}

                {page.text === "SOLAR POWER" && (
                <DropDown>
                    <Rlink
                    to={`/ppa`}
                    style={{
                        textTransform: "uppercase",
                        textDecoration: "none",
                        textAlign: "left",
                    }}
                    >
                    Power Purchase Agreement
                    </Rlink>
                    {/* <Rlink
                    to={`/turnkey-solutions`}
                    style={{
                        textTransform: "uppercase",
                        textDecoration: "none",
                        textAlign: "left",
                    }}
                    >
                    Turnkey Solutions
                    </Rlink> */}
                </DropDown>
                )}

            </DropDownBox>
            ))}
        </Box>
        </Toolbar>
    </Container>
    </AppBar>
);
}
export default NavBar;
