import { Box, Grid, IconButton, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
    footer: {
    backgroundColor: '#EEEFF1',
    padding: theme.spacing(1, 0),
    },
    logo: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    },
    logoText: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
    
    },
    icon: {
    color: '#000000',
    marginLeft: theme.spacing(2),
    },
    copyright: {
    paddingTop: theme.spacing(1),
    textAlign: 'center',
    },
    responsiveFooterContainerItemStart: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: theme.spacing(1),
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center', // For small and medium breakpoints
        },
    },
    responsiveFooterContainerItemCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: theme.spacing(1),
        flexDirection: 'row'
    },
    responsiveFooterContainerItemEnd: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        paddingTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center', // For small and medium breakpoints
        },
    }
}));

function Footer() {
    const classes = useStyles();

    return (
    <footer className={classes.footer}>
        <div
        style={{
            marginInline: "auto",
            maxWidth: "1200px",
        }}
        >
            <Grid alignItems="center" justifyContent='center' container maxWidth={'1200px'}>
                <Grid xs={12} sm={12} md={4} >
                    <div className={classes.responsiveFooterContainerItemStart}>
                        <img src={"/greenwatt-transparent.png"} alt="Greenwatt Solutions" style={{ width: '50%', height: '50%', alignSelf: 'flex-start', marginTop: '1rem' }} />
                    </div>
                </Grid>
                <Grid xs={12} sm={12} md={4}>
                    <div className={classes.responsiveFooterContainerItemCenter}>
                        <Link href="/about-us" style={{ marginRight: 16, color:"#000000" }}>
                        About Us
                        </Link>
                        {/* <Link href="/" style={{ marginRight: 16, color:"#000000" }}>
                        Products
                        </Link> */}
                        <Link href="/contact-us" style={{ marginRight: 16, color:"#000000" }}>
                        Contact Us
                        </Link>
                    </div>
                </Grid>
                <Grid xs={12} sm={12} md={4}>
                    <div className={classes.responsiveFooterContainerItemEnd}>
                        <IconButton href="https://www.linkedin.com/company/greenwatt-solutions/" target="_blank" rel="noopener noreferrer" className={classes.icon}>
                        <FaLinkedin size={24} />
                        </IconButton>
                        <IconButton href="https://www.facebook.com/profile.php?id=61556680998004" target="_blank" rel="noopener noreferrer" className={classes.icon}>
                        <FaFacebook size={24} />
                        </IconButton>
                        <IconButton href="https://www.instagram.com/greenwattsolutions/" target="_blank" rel="noopener noreferrer" className={classes.icon}>
                        <FaInstagram size={24} />
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            <div
            className={`${classes.responsiveFooterContainerItemStart} ${classes.copyright}`}
            >
                <Typography variant="caption" style={{ color: '#000000' }}>
                    COPYRIGHT © 2024 GREENWATT PTE. LTD. ALL RIGHTS RESERVED.
                </Typography>
            </div>
        </div>
    </footer>
    );
}

export default Footer;