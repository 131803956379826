import styled from "@emotion/styled";

const HeroSecDiv = styled.div`
    height: 25vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
        url(${(props) => props.backgroundImage});
    background-position: center;
    background-size: cover;
    display: grid;
    place-content: center;
    color: white;
    text-align: center;

    h2 {
        font-size: 2.4rem;
        font-weight: 600;
        text-transform: capitalize;
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
        "Courier New", monospace;
    }

    p {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-weight: 600;
        text-transform: capitalize;
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
        "Courier New", monospace;
    }
`

export const Hero = ({backgroundImage,children}) => {
  return (
    <HeroSecDiv backgroundImage={backgroundImage}>
      {children}
    </HeroSecDiv>
  );
}
