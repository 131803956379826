import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function DataTable({dataFrame}) {
  let keys = Object.keys(dataFrame[0])
  return (
      dataFrame && dataFrame.length && keys.length ?
      <TableContainer component={Paper} sx={{
        width: '100%'
      }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {keys.map((value, index) => {
                return (
                  <TableCell key={index} align="right">{value}</TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              dataFrame.map((row, index) => {
                return(
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {
                      keys.map((key, index) => {
                        return (
                          <TableCell key={`${row}-${key}`} align="right">{row[key]}</TableCell>
                        )
                      })
                    }
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      : null
  )
}
