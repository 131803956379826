import DataTable from "./Table/DataTable.js"
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import Stack from '@mui/material/Stack';

function componentBuilder(content) {
  return(
      <Box sx={{
        textAlign: 'center',
        flexGrow: '0'
      }}>
          {
              content.map(({type, value}, i) => {
                  switch (type) {
                      case "image":
                      return (
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          alignItems: 'center'
                        }}>
                          <img 
                          src={value.imagePath} 
                          loading="lazy"
                          draggable={false}
                          key={i}
                          />
                          {value.shouldDisplayCaption && (<Typography variant="body2" fontWeight={"bold"}>{value.caption}</Typography>)}
                        </Box>
                    )
                      case "text":
                      return (
                      <Typography
                        key={i}
                        variant={value.variant}
                      >{value.content}</Typography>
                      )
                      case "dataTable":
                      return (
                        <DataTable dataFrame={value.dataFrame} key={i}/>
                      )
                      case "labelledText":
                      return (
                        <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'start',
                          width: '100%',
                        }}
                        key={i}
                        >
                          <Typography
                          textAlign={"start"}
                            variant={value.variant}
                            fontWeight={"bold"}
                          >{value.label} :</Typography>
                          <Box mr={2} />
                          <Typography
                            variant={value.variant}
                          >{value.content}</Typography>
                        </Box>
                      )
                      default:
                      return (<></>);
                  }
              })
          }
      </Box>
  )
}

export default function recursiveComponentBuilder(content) {
  return (
    <>
      {content.map(({ type, value }, i) => {
        console.log(type + ": " + value);

        let contentElement = null;

        // Handle non-recursive content using the componentBuilder function
        if (type !== 'container') {
          contentElement = componentBuilder([{ type, value }])
        } else {
          // If the item has children, handle recursion
          contentElement = (
            <Stack 
            spacing={1} 
            direction={value.orientation === 'horizontal' ? 'row' : 'column'} 
            sx={{
              margin: 'auto',
              display: { xs: 'block', md: 'flex'},
              alignItems: 'center',
              justifyContent: 'center'
            }}
            >    
              {recursiveComponentBuilder(value.children)} 
            </Stack>
          );
        }

        return contentElement;
      })}
    </>
  );
}
