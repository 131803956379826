import { useNavigate } from "react-router-dom";
import { Button, Typography, Box } from "@mui/material";
import { Hero } from "../Hero/Hero";

export const ContactUsHero = () => {
  let navigate = useNavigate()
  return (
  <Hero backgroundImage={"/contact-us.png"}>
    <Box sx={{
      alignContent: 'center'
    }}>
      <Typography variant="h4" color={"white"} mb={2}>Go GREEN with GREENWATT.</Typography>
      <Button variant="contained" color="success" onClick={()=>{
        navigate("/contact-us");
      }}>Contact Us</Button>
    </Box>
  </Hero>
  )
}