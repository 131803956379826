import React from "react";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from "@mui/lab";
import { Typography, Box } from "@mui/material";
import { timelineItemClasses } from '@mui/lab/TimelineItem';

const CustomTimeline = ({imageUrl, eventsArray}) => {
  return (
    <>
    {imageUrl && eventsArray && eventsArray.length > 0 && (
      <Box display={'flex'} sx={{
        my: 5
      }}>
      <Box sx={{
        flex: 1,
      }}>
        <img src={imageUrl} style={{ height: "100%", width: "100%", objectFit: 'cover'}} />
      </Box>
      <Box sx={{
        flex: 1,
        alignSelf: "flex-start"
      }}>
        {/* Spacer  */}
        <Box sx={{ mt: 3 }} />
        <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 1,
          },
        }}
        >
          {eventsArray.map((event, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                {index !== eventsArray.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h6" color="primary" fontWeight="bold">
                  {event.year}
                </Typography>
                <Typography variant="body1">{event.description}</Typography>
                {/* Spacer  */}
                <Box sx={{ mb: 3 }} />
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </Box>
    )}
    </>
  );
};

export default CustomTimeline;
