import styled from "@emotion/styled";
import { IconButton } from "@mui/material";

export const ImageCardComponent = ({shouldHover, shouldImageExpand, productData, productType, btnAction, buttonIcon}) => {
  shouldHover = shouldHover || true;
  shouldImageExpand = shouldImageExpand || true;
  const haveActionButton = btnAction !== undefined && buttonIcon !== undefined

  const StyledImageCardContainer = styled.div`
  display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates three columns */
    gap: 20px;
    padding: 50px 0;

    .image-card {
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        text-decoration: none;

        img {
            width: 100%;
            object-fit: cover;
            transition: transform 0.3s;
        }

        .content {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          
          margin: 10px;

          .title {
              text-align: text-start;
              font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
              color: #333;
              font-weight: 500;
              flex-grow: 1;
              flex: 1 1 auto;
              padding-inline: 10px;
              word-break: break-word;
          }

          .icon-button {
            z-index:1;
          }
        }

        &:hover img {
            transform: ${(props) => (props.shouldImageExpand ? "scale(1.1)" : "none")};
        }

        &:hover {
          box-shadow: ${(props) => (props.shouldHover ? "3px 3px 3px gray" : "none")};
        }

    }

    @media screen and (max-width: 899px) {
        grid-template-columns: repeat(2, 1fr); /* Adjusts to two columns */
    }

    @media screen and (max-width: 599px) {
        grid-template-columns: 1fr; /* Adjusts to one column */
    }
  `

  return (
    <StyledImageCardContainer shouldHover shouldImageExpand>
      {productData.map((data, index) => (
          <a 
          key={index} 
          href={`/product/${productType}/${data.productTitle}`} 
          className="image-card">
            <img src={data.imagePath} alt={`Solar panel ${index + 1}`} />
            <div className="content">
                <p 
                className="title"
                sx={{
                  width: "100%"
                }}
                >{data.productTitle}</p>
                {
                  haveActionButton &&
                  <IconButton
                    className="icon-button"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent hyperlink activation
                      console.log("Download clicked");
                      if (btnAction) {
                        btnAction(data)
                      }
                    }}
                  >
                    {buttonIcon}
                  </IconButton>
                }
            </div>
          </a>
      ))}
    </StyledImageCardContainer>
  );
}