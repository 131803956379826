import React from "react";
import { ImageCardComponent } from "../components/Cards/ImageCard";
import Container from "@mui/material/Container";
import {retrieveProductsByCategoryName} from "../utils/productParser";
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import BaseLayout from "../components/BaseLayout";
import { useParams, Navigate } from "react-router-dom";

export default function ProductPage() {
    const {productType} = useParams()
    const productData = retrieveProductsByCategoryName(productType)

    // Redirect to Not Found page if no products exist
    if (!productData || productData.length === 0) {
      return <Navigate to="/error" />;
  }

    const handleDownload = (productData) => {
      // Create an anchor tag dynamically and simulate a click to trigger the download
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = productData.specSheetPath; // URL to the static asset
      link.download = productData.specSheetPath.split("/").pop(); // Use file name if provided, otherwise fallback to the last part of the URL
      link.click();
    };

    return (
      <BaseLayout
        heroContent={
            <>
                <h2>Solar Panels</h2>
                <p>
                    <span>Products</span>
                    <span>&gt;</span>
                    <span>{productType}</span>
                </p>
            </>
        }
        heroBackgroundImage="/img/customize/solarImage.png"
      >
          <Container>
            <ImageCardComponent 
            shouldHover={false} 
            productData={productData} 
            productType={productType} 
            buttonIcon={<FileDownloadRoundedIcon />} 
            btnAction={handleDownload}
            />
          </Container>
      </BaseLayout>
    );
}
