import React, { useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, IconButton } from "@mui/material";
import {
Card,
CardContent,
CardMedia,
CardActions,
} from "@material-ui/core";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { Link as Rlink } from "react-router-dom";

import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import ConstructionIcon from "@mui/icons-material/Construction";
import StoreIcon from "@mui/icons-material/Store";
import WavesIcon from "@mui/icons-material/Waves";
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';

import "./Landing.css";
import { Typography } from "@mui/material";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { SiAdguard } from "react-icons/si";
import { SiLeaderprice } from "react-icons/si";
import { FaAward } from "react-icons/fa";

import { useLocation } from "react-router-dom";
import { ContactUsHero } from "../components/ContactUs/ContactUsHero";

export const Gmap = styled.div`
margin: 100px 0;
display: grid;
grid-template-columns: repeat(1, 1fr);
gap: 20px;

.map {
}

.info-cont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    text-align: left !important;
}

.location-info {
    text-transform: capitalize;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    text-align: left;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;

    color: #606060;

    svg {
    font-size: 2rem;
    }

    .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 20px;
    }
}

.abs-info {
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: #fff;
    transform: translate(-50%, -10%);
    width: 76%;

    padding: 20px 40px;
    border-radius: 0.8rem;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;

    div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;

    color: #606060;
    }
}

@media screen and (max-width: 899px) {
    .info-cont {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    }
}
`;

export default function Landing() {
const location = useLocation();
const currentHash = location.hash;

const homeRef = useRef(null);

useEffect(() => {
    setTimeout(() => {
    if (currentHash) {
        console.log(currentHash);
        let dom = document.querySelector(`[href="${currentHash}"]`);
        dom.click();
    }
    }, 100);
}, [currentHash]);

function handleNavClick(href) {
    console.log(href);
    setTimeout(() => {
    if (href) {
        console.log(href.split("#")[1]);
        let dom = document.querySelector(`[href="#${href.split("#")[1]}"]`);
        dom.click();
    }
    }, 100);
}

return (
    <Box ref={homeRef} sx={{ overflowX: "hidden" }} className="main-container">
    <NavBar handleNavClick={handleNavClick} />

    {/* <Box className="landing-content"> */}
    <Grid
        container
        sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        width: "100vw",
        height: "auto",
        backgroundImage: `url("./solarpanel_hero.jpg")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        }}
    >
        {/* HERO SECTION */}
        <Grid item xs={12} sm={12} md={4}>
        <Box sx={{ maxWidth: "100%", height: "auto" }}>
            {" "}
            {/* Add maxWidth and height properties */}
            <img src="../solarpanel_icon.png" alt="cummins-hero" />{" "}
            {/* Add height and width properties */}
        </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
        <Box
            ml={{ xs: 0, md: 18 }}
            mb={6}
            sx={{ maxWidth: { xs: "100%", sm: "100%" } }}
        >
            <Typography
            variant="h2"
            sx={{
                textAlign: {
                xs: "center",
                md: "left",
                },
            }}
            >
            GREENWATT SOLAR
            <br />
            <Typography variant="h4" fontStyle="italic">
                Solar at ZERO Cost.
            </Typography>
            </Typography>
            <Box
            mt={4}
            mb={2}
            sx={{
                textAlign: {
                xs: "center",
                md: "left",
                },
            }}
            >
            <Button
                variant="contained"
                component="a"
                href="/contact-us"
                style={{
                fontWeight: "bold",
                backgroundColor: "#00A36C", // change to your desired color
                color: "#FFFFFF", // change to contrast with the background color
                }}
            >
                Contact Sales
            </Button>
            </Box>
        </Box>
        </Grid>
    </Grid>
    {/* </Box> */}

    {/* ABOUT US SECTION */}
    <div id="about">
        <Grid
        container
        sx={{
            backgroundColor: "#00A36C",
            padding: "20px 0",
        }}
        >
        <Grid item xs={12} md={4}>
            <FaAward size={42} color={"#FFFFFF"} />
            <Typography variant="h5" color="#FFFFFF" fontWeight="700" my={1}>
            EXPERIENCED TEAM
            </Typography>
            <Typography variant="subtitle1" color="#FFFFFF">
            We have experience <br /> operating in South-east
            Asia
            </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
            <SiAdguard size={42} color={"#FFFFFF"} />
            <Typography variant="h5" color="#FFFFFF" fontWeight="700" my={1}>
            ACCIDENT-FREE
            </Typography>
            <Typography variant="subtitle1" color="#FFFFFF">
            We have a zero accident track record <br /> since the start of our
            business operations
            </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
            <SiLeaderprice size={42} color={"#FFFFFF"} />
            <Typography variant="h5" color="#FFFFFF" fontWeight="700" my={1}>
            AFFORDABLE
            </Typography>
            <Typography variant="subtitle1" color="#FFFFFF">
            We offer competitive prices
            <br />
            without compromising on product quality
            </Typography>
        </Grid>
        </Grid>
    </div>

    {/* PRODUCTS SECTION */}
    <Box id="products">
        <Grid
        container
        spacing={0}
        sx={{
            // display: 'flex',
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            py: 10,
            px: 2,
        }}
        >
        <Grid item xs={12} sm={12} md={5} component="section">
            <Typography
            sx={{ textAlign: "start", color: "#00704a" }}
            fontSize={30}
            fontWeight={700}
            id="products"
            >
            PRODUCTS
            </Typography>

            <Typography
            variant="h6"
            component="h4"
            sx={{
                fontWeight: "500",
                paddingTop: 1,
                textAlign: "start",
            }}
            >
            Solar PV System for Singapore
            </Typography>

            <Typography
            variant="body1"
            sx={{
                maxWidth: "75%",
                textAlign: "start",
                mx: "0",
                paddingBottom: "10px",
            }}
            >
            All of our solar panels come with a 25 year material warranty and 30 year linear power warranty.
            </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
            <Card
            square={true}
            sx={{
                minHeight: 200,

                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                border: "1px solid #ccc",
            }}
            >
            <CardContent>
                <IconButton>
                <SolarPowerIcon fontSize="large" color="secondary" />
                </IconButton>
                <Typography
                variant="h5"
                component="p"
                sx={{
                    fontWeight: 700,
                    textTransform: "capitalize",
                }}
                >
                Solar Panels
                </Typography>
            </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
            <Card
            square={true}
            sx={{
                minHeight: 200,

                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                boxShadow: "none",
                border: "1px solid black",
            }}
            >
            <CardContent>
                <IconButton>
                <BloodtypeIcon fontSize="large" color="secondary" />
                </IconButton>
                <Typography
                variant="h5"
                component="p"
                sx={{
                    fontWeight: 700,
                    textTransform: "capitalize",
                }}
                >
                Inverters
                </Typography>
            </CardContent>
            </Card>
        </Grid>

        <Grid
            item
            xs={12}
            sm={6}
            md={2}
            sx={{
            display: { xs: "none", sm: "flex" },
            }}
        >
            <Card
            square={true}
            elevation={0}
            sx={{
                boxShadow: "none !important",
                border: "none !important",

                minHeight: 180,

                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
            }}
            >
            <CardContent>
                <ArrowCircleRightRoundedIcon
                fontSize="large"
                color="secondary"
                />
            </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
            <Card
            square={true}
            sx={{
                minHeight: 200,

                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                border: "1px solid #ccc",
            }}
            >
            <CardContent>
                <IconButton>
                <StoreIcon fontSize="large" color="secondary" />
                </IconButton>
                <Typography
                variant="h5"
                component="p"
                sx={{
                    fontWeight: 700,
                    textTransform: "capitalize",
                }}
                >
                Residential Applications
                </Typography>
            </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
            <Card
            square={true}
            sx={{
                minHeight: 200,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                border: "1px solid #ccc",
            }}
            >
            <CardContent>
                <IconButton>
                <WavesIcon fontSize="large" color="secondary" />
                </IconButton>
                <Typography
                variant="h5"
                component="p"
                sx={{
                    fontWeight: 700,
                    textTransform: "capitalize",
                }}
                >
                Marine Offshore Applications
                </Typography>
            </CardContent>
            </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
            <Card
            square={true}
            sx={{
                minHeight: 200,

                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                border: "1px solid #ccc",
            }}
            >
            <CardContent>
                <IconButton>
                <ConstructionIcon fontSize="large" color="secondary" />
                </IconButton>
                <Typography
                variant="h5"
                component="p"
                sx={{
                    fontWeight: 700,
                    textTransform: "capitalize",
                }}
                >
                Commercial Applications
                </Typography>
            </CardContent>
            </Card>
        </Grid>
        </Grid>
    </Box>

    {/* BANNER OF TRUSTED BRANDS */}
    <Box mb={4} className="banner">
        <Typography
        variant="h6"
        component="h6"
        sx={{
            fontWeight: "600",
            padding: 1,
            px: 2,
            textAlign: "center",
            // fontStyle: 'italic',
            color: "#808080",
            display: { xs: "none", sm: "block" },
        }}
        >
        BRAND PARTNERS
        </Typography>
        <Grid
        container
        spacing={3}
        sx={{
            alignItems: "center",
            justifyContent: "center",
            display: { xs: "none", sm: "flex" },
        }}
        >
        <Grid item xs={12} sm={6} md={3}>
            <img
            src="../jinko_icon.png"
            alt="jinko logo"
            style={{
                width: "240px",
                height: "100px",
                objectFit: "contain",
                filter: "grayscale(100%)",
            }}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <img
            src="../trina_icon.png"
            alt="trina logo"
            style={{
                width: "240px",
                height: "100px",
                objectFit: "contain",
                filter: "grayscale(100%)",
            }}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <img
            src="../longi_icon.png"
            alt="longi logo"
            style={{
                width: "240px",
                height: "100px",
                objectFit: "contain",
                filter: "grayscale(100%)",
            }}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <img
            src="../growatt_icon.png"
            alt="growatt logo"
            style={{
                width: "240px",
                height: "100px",
                objectFit: "contain",
                filter: "grayscale(100%)",
            }}
            />
        </Grid>
        </Grid>
    </Box>

    {/* CONTACT SECTION */}
    <ContactUsHero />
    {/* <div id="contact">
        <Typography
        sx={{ paddingLeft: "20px", color: "#00704a" }}
        fontSize={30}
        fontWeight={700}
        id="contact"
        >
        GET IN TOUCH
        </Typography>

        <Box>
        <Grid
            container
            sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            }}
        >
            <ContactUs />
            <ToastContainer />
        </Grid>
        </Box>
    </div> */}

    <Box>
        <Footer />
    </Box>
    </Box>
);
}
