
import BaseLayout from "../components/BaseLayout";
import FeatureCardGrid from "../components/Cards/FeatureCardGrid";
import { Box, Typography } from "@mui/material";


export default function TurnKeyPage() {

    const BenefitsData = [
      {
        title: "No Upfront Cost",
        description: "Under a PPA, Greenwatt finances, installs, and maintains the solar PV system. No need to worry about upfront capital costs or the annual recurring cost of maintaining the system.",
        icon: "img/ppa/no-upfront-cost.png",
      },
      {
        title: "Immediate Cost Savings via Low Solar Tariff",
        description: "We integrate the system onto your premises so you can use the green energy you produce on-site. In return, you receive the benefit of a low long-term tariff for the solar energy you consume.",
        icon: "img/ppa/immediate-cost-saving.png"
      },
      {
        title: "Integrated Operation and Maintenance Services",
        description: "Greenwatt operates and maintains the system to ensure it is in tip-top condition. We maintain the system for as long as it is up and running, and your property enjoys clean energy and savings.",
        icon: "img/ppa/service.png",
      },
      {
        title: "No Price Fluctuations",
        description: "Your property is protected from unpredictable wholesale energy prices. With your own system in place, you can lock in fixed electricity prices and enjoy predictable energy expenses.",
        icon: "img/ppa/lock-in.png"
      }
    ]

    const HowItWorksData = [
      {
        title: "Generate Additional Revenue",
        description: "Excess power generated can be sold back to the grid, earning additional passive revenue to offset operational costs. Additionally, passive income earned by selling electricity back to the grid is not taxable.",
        icon: "img/services/delivery.jpg"
      },
      {
        title: "Generate Additional Revenue",
        description: "Excess power generated can be sold back to the grid, earning additional passive revenue to offset operational costs. Additionally, passive income earned by selling electricity back to the grid is not taxable.",
        icon: "img/services/maintenance.jpg"
      },
      {
        title: "Generate Additional Revenue",
        description: "Excess power generated can be sold back to the grid, earning additional passive revenue to offset operational costs. Additionally, passive income earned by selling electricity back to the grid is not taxable.",
        icon: "img/services/maintenance.jpg"
      },
      {
        title: "Generate Additional Revenue",
        description: "Excess power generated can be sold back to the grid, earning additional passive revenue to offset operational costs. Additionally, passive income earned by selling electricity back to the grid is not taxable.",
        icon: "img/services/maintenance.jpg"
      }
    ]

    return (
      <BaseLayout
        heroContent={
          <Box sx={{
            p: 4,
            maxWidth: "600px",
          }}>
            <h2>MORE WAYS SOLAR PV SYSTEMS BOOST BUSINESSES</h2>
          </Box>
        }
        heroBackgroundImage="/img/hero/ppa-hero-image.webp"
      >
        <Box
        mx={'auto'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '1200px'
        }}
        >
          <Box sx={{ 
              color: "#111", 
              p: 4 
            }}
          >
            {/* Spacer  */}
            <Box sx={{ mb: 2 }} />

            <Typography variant="h7" fontWeight={"bold"}>
            Business owners, stakeholders, investors: if you own the building your business is in or lease the entire building or facility, these turnkey solutions are for you. These assets can help you offset monthly electricity bills and provide passive revenue when you export excess solar energy to the grid. They also help you achieve your Environmental, Social, and Governance (ESG) strategy and Development Sustainability Goals (DSG). This means lower operating costs and, with ESG and DSG compliance, more business opportunities. Win-win.
            </Typography>
            {/* Spacer  */}
            <Box sx={{ mb: 2 }} />

            {/* Benefits at a glance Section */}
            <Typography variant="h5" fontWeight="bold" textAlign={'center'}>
              Benefits at a glance
            </Typography>

            {/* Spacer  */}
            <Box sx={{ mb: 2 }} />
            {/* Benefits at a glance */}
            <FeatureCardGrid features={BenefitsData}/>   

            {/* Spacer  */}
            <Box sx={{ mb: 2 }} />

            {/* How it works Section */}
            <Typography variant="h5" fontWeight="bold" textAlign={'center'}>
              How it works
            </Typography>
            
            {/* Spacer  */}
            <Box sx={{ mb: 2 }} />

            {/* How it Works */}
            <FeatureCardGrid features={HowItWorksData} />            

            {/* Spacer  */}
            <Box sx={{ mb: 4 }} />

          </Box>
        </Box>
      </BaseLayout>
    );
}
