import React from "react";
import ReactDOM from "react-dom/client";
import "swiper/css";
import "swiper/css/pagination";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ProductPage from "./Customize/productPage"
import ProductDetailsPage from "./Customize/ProductDetailsPage";

import Success from "./components/Success";
import NotFoundPage from "./NotFoundPage";
import PPAPage from "./Pages/PPAPage";
import TurnKeyPage from "./Pages/TurnkeyPage";
import ContactUs from "./components/ContactUs";
import AboutUsPage from "./Pages/AboutUsPage";

const router = createBrowserRouter([
  {
    path: "/",  
    element: <App />,
  },
  {
    path: "/ppa",
    element: <PPAPage />,
  },
  {
    path: "/turnkey-solutions",
    element: <TurnKeyPage />,
  },
  {
    path: "/product/:productType",
    element: <ProductPage/>,
  },
  {
    path: "/product/:productType/:productModel",
    element: <ProductDetailsPage/>,
    errorElement: <NotFoundPage />
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/success",
    element: <Success />,
  },
  {
    path: "/about-us",
    element: <AboutUsPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  }
  // {
  //   path: "/alternators",
  //   element: <Alternators />,
  // },
  // {
  //   path: "/diesel-generator",
  //   element: <Customize data={jsonData} />,
  // },
  // {
  //   path: "/solar-panel",
  //   element: <Customize2 />,
  // },
  // {
  //   path: "/solar-panel/gs445w",
  //   element: <GS445W  />,
  // },
  // {
  //   path: "/solar-panel/gs450w",
  //   element: <GS450W  />,
  // },
  // {
  //   path: "/solar-panel/gs500w",
  //   element: <GS500W  />,
  // },
  // {
  //   path: "/solar-panel/gs600w",
  //   element: <GS600W  />,
  // },
  // {
  //   path: "/solar-panel/gs650w",
  //   element: <GS650W  />,
  // },
  // {
  //   path: "/generator/open-type",
  //   element: <Customize data={jsonData} />,
  // },
  // {
  //   path: "/generator/silent-type",
  //   element: <Customize data={jsonData} />,
  // },
  // {
  //   path: "/generator/controller",
  //   element: <Customize data={jsonData} />,
  // },
  // {
  //   path: "/products-consultation",
  //   element: <Consultation />,
  // },
  // {
  //   path: "/delivery-and-installation",
  //   element: <Transportation />,
  // },
  // {
  //   path: "/after-sales",
  //   element: <AfterSales />,
  // },
  // {
  //   path: "/admin",
  //   element: <AdminLogin />,
  // },
  // {
  //   path: "/admin/dashboard",
  //   element: (
  //     <PrivateRoute>
  //       <AdminDashboard />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   path: "/admin/dashboard/customers",
  //   element: (
  //     <PrivateRoute>
  //       <Customers />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   path: "/admin/dashboard/create-bill",
  //   element: (
  //     <PrivateRoute>
  //       <CreateBill />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   path: "/customers/:customerId", // Add dynamic route
  //   element: <CustomerDetails />,
  // },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
