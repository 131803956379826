import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import styled from "@emotion/styled";
import { Hero } from "./Hero/Hero";
import { ContactUsHero } from "./ContactUs/ContactUsHero";
import { Box } from "@mui/material";

const WhatsAppIcon = styled.a`
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 24px;
    color: #25D366;
    background: #fff;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    z-index: 1000;
`;

const BaseLayout = ({ children, heroContent, heroBackgroundImage, shouldShowContactUsFooterBanner=true }) => {
    return (
        <Box 
        my={0}
        p={0}
        sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            <NavBar />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1
            }}>
                {heroContent && heroBackgroundImage && (
                    <Hero backgroundImage={heroBackgroundImage}>
                        {heroContent}
                    </Hero>
                )}
                <main>{children}</main>
                {shouldShowContactUsFooterBanner && (
                    <ContactUsHero />
                )}
            </Box>
            <WhatsAppIcon
                href="https://wa.me/601158777451"
                target="_blank"
                rel="noopener noreferrer">
                <i className="fa fa-whatsapp"></i>
            </WhatsAppIcon>
            <Footer />
        </Box>
    );
};


export default BaseLayout;
