import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import recursiveComponentBuilder from "../ComponentBuilder.js"


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{
        p: 3,
        margin: 'auto',
      }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabView({tabData, currentTab, setCurrentTab}) {

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
        <Box 
        sx={{ 
          // borderBottom: 1, 
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'center', // This centers the Tabs horizontally
          alignItems: 'center', // This centers the Tabs vertically (optional for a typical horizontal layout)
          width: '100%'
        }}>
          <Tabs 
          value={currentTab} 
          onChange={handleChangeTab} 
          indicatorColor="inherit"
          textColor="inherit"
          sx={{
            flexGrow: 0, // Ensures the Tabs don't expand unnecessarily
            // Change color of the Tab when inactive (default state)
            '& .MuiTabs-indicator': {
              backgroundColor: 'green', // Custom color for the indicator (blue in this case)
            },
          }}
          >
            {
              tabData.map((tab, i) => {
                return (<Tab 
                  key={i}
                  label={tab.label} 
                  {...a11yProps(i)}
                  sx={{
                    fontWeight: '700',
                    color: '#757575', // Inactive tab text color (light gray)
                    '&.Mui-selected': {
                      color: 'green', // Active tab text color (blue)
                      backgroundColor: 'none', // Active tab background color
                    }
                  }}
                  />)
              })
            }
          </Tabs>
        </Box>
        {
          tabData.map((tab, i) => {
            return (
            <CustomTabPanel key={i} value={currentTab} index={i}>
              {recursiveComponentBuilder(tab.content)}
            </CustomTabPanel>
            )
          })
        }
    </Box>
  )
}