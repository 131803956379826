
import BaseLayout from "../components/BaseLayout";
import { Box, Typography } from "@mui/material";
import CustomTimeline from "../components/Timeline/Timeline";


export default function AboutUsPage() {
    const timelineEvents = [
      { year: "2024", description: "GREENWATT was founded in Singapore and Malaysia with the mission of accelerating the green transition by providing sustainable and cost-effective energy solutions. Committed to reducing carbon footprints and promoting energy independence, we empower businesses and homeowners with high-quality solar power systems." },
      { year: "2023", description: "Entered the Indonesia market to provide tailored energy solutions for hospitals, mining companies and other commercial sectors." },
      { year: "2012", description: "Our group company GSP was founded to provide one stop reliable power generator solution in Myanmar, with a strong focus on telecommunications and mining sectors." },
      // { year: "2012", description: "Greenwatt Energy Group was honoured with a Top 10 win at the E50 Awards for its remarkable 32-year growth journey as one of Singapore’s most trusted brands." }
    ];

    return (
      <BaseLayout
        heroContent={
          <Box
          sx={{
            maxWidth: "600px",
            alignItems: "center",
            marginInline: "auto",
            padding: "20px"
          }}
          >
            <h1>LEADING THE GREEN TRANSITION</h1>
            <Box sx={{ mb: 1 }} />       
            {/* <h4>As one of Singapore's leading energy providers, we are commited to empowering communities with good choices -- choices that are affordable, make sense, and care about the planet</h4> */}
          </Box>
          
        }
        heroBackgroundImage="/img/hero/about-hero-image.png"
      >
        <Box
        mx={'auto'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '1200px'
        }}
        >
          <Box sx={{ 
              color: "#111", 
              p: 4 
            }}
          >
            {/* Energy Experts Section */}
            <Typography variant="h5" fontWeight="bold" >
              From All Energy to Clean Energy
            </Typography>
            <Box sx={{ mb: 2 }} />

            <Typography variant="body1">
            GREENWATT was founded with the vision of becoming a trusted power solutions provider in Southeast Asia, dedicated to delivering sustainable and cost-effective energy solutions. 
            </Typography>
            <Box sx={{ mb: 1 }} />

            <Typography variant="body1">
            Our mission is to empower businesses and homeowners with clean, renewable energy while reducing reliance on traditional fossil fuels. Through innovative technology, strategic partnerships, and customer-centric services, we aim to drive the transition toward a greener and more energy-efficient future across the region.
            </Typography>
            {/* Spacer  */}
            <Box sx={{ mb: 4 }} />

            {/* Spacer  */}
            <Box sx={{ mb: 2 }} />
          </Box>
        </Box>

        {/* what make our approach unique section */}
        <Box
          sx={{
            p:4,
            backgroundColor: "#F0F0F0",
            color: "#0D1321"
          }}
          >
            <Box
            mx={'auto'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '20px',
              justifyContent: 'center',
              // alignItems: 'center',
              maxWidth: '1200px'
            }}
            >
              <Typography variant="h4" fontWeight="bold" textAlign={'center'}>
              How we stand out
              </Typography>
              <Box sx={{ mb: 2 }} />

              <Typography variant="h5" fontWeight="bold">
              QUALITY
              </Typography>
              <Box sx={{ mb: 1 }} />
              <Typography variant="h7" fontWeight="500">
              We only utilize materials of the highest quality and innovation to ensure maximum efficiency and durability in our solar energy solutions. Our photovoltaic (PV) systems feature Bloomberg Tier 1 JINKO/TRINA Solar panels, renowned for their superior performance and reliability. Additionally, we incorporate industry-leading inverters from trusted brands such as Huawei and Growatt, ensuring optimal energy conversion, system stability, and long-term sustainability. By leveraging these top-tier components, GREENWATT guarantees high energy yields, seamless operation, and a long-lasting solar investment for both residential and commercial property owners.
              </Typography>
              <Box sx={{ mb: 2 }} />

              <Typography variant="h5" fontWeight="bold">
              ZERO COST
              </Typography>
              <Box sx={{ mb: 1 }} />
              <Typography variant="h7" fontWeight="500">
              To date, we are the only solar developer company that not only offers ZERO upfront capital expenditure, but also ZERO monthly costs.
              </Typography>
              <Box sx={{ mb: 2 }} />
              
              <Typography variant="h5" fontWeight="bold">
              FIXED DISCOUNT, NOT FIXED RATE
              </Typography>
              <Box sx={{ mb: 1 }} />
              <Typography variant="h7" fontWeight="500">
              What does it mean? This means that your electricity rate is directly linked to the current electricity tariff. If the electricity tariff rate decreases, your contracted electricity rate will also decrease accordingly, ensuring that you always benefit from cost savings.
              </Typography>
              <Box sx={{ mb: 2 }} />
            </Box>
        </Box>

        {/* Timeline section */}
        <Box
        mx={'auto'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '1200px'
        }}>
          <CustomTimeline imageUrl={"/img/hero/greenwattlogopost.png"} eventsArray={timelineEvents}/>
        </Box>
      </BaseLayout>
    );
}
