
import BaseLayout from "../components/BaseLayout";
import FAQ from "../components/FAQ/FAQ";
import FeatureCardGrid from "../components/Cards/FeatureCardGrid";
import { Box, Typography } from "@mui/material";


export default function PPAPage() {
    const PPAFAQData = [
      {
        question: "What is the difference between a solar lease and a PPA?",
        answer: "Solar Leasing is a program where the customer leases the solar PV system from a solar company. The customer pays monthly for the leasing of the solar PV system.",
      },
      {
        question: "What is the difference between GREENWATT's PPA and PPAs offered by other solar developers?",
        answer: "To date, GREENWATT is the only solar developer company that offers both zero upfront costs and zero monthly repayments. To our knowledge, other solar developers may offer zero upfront costs but charge monthly for the solar PV system.",
      },
      {
        question: "What happens if I decide to purchase the solar PV system after a few years?",
        answer: "Yes, you can do so, and we're happy that you're ready to own the solar PV system! You can select an early buy-out option and pay down the straight line depreciated value on the system with no prepayment penalty.",
      },
      {
        question: "What happens if the system breaks or isn't working as expected?",
        answer: "Our team is able to remotely monitor the system through the application. If the system is not working as expected, we will schedule a site visit and rectify the problem, at no cost to you.",
      },
      {
        question: "What happens if I want to sell my property?",
        answer: "No problem! If you decide to sell, we will assist you in transferring the service to the new owner. Our team will work with property agents and the new owner to assist in the service transfer, so your move happens as smoothly as possible. Alternatively, you may also choose to pay down the remaining value of the solar PV system and add it onto your property value.",
      },
    ];

    const BenefitsData = [
      {
        title: "Zero Cost",
        description: "With a PPA, GREENWATT covers material, installation, maintenance and licensing costs. You do not need to worry about the Solar PV System.",
        icon: "img/ppa/zerocost.png",
      },
      {
        title: "Fixed Discount",
        description: "Our plans offer discounted electricity rate over the entire contract period. Greener & Always Cheaper.",
        icon: "img/ppa/fixeddiscount.png"
      },
      {
        title: "Worry-free Solar",
        description: "GREENWATT takes care of your servicing and maintenance schedule to ensure your system is performing at its maximum efficiency.",
        icon: "img/ppa/worryfree.png"
      },
    ]

    const HowItWorksData = [
      {
        title: "Discounted Rate",
        description: "You enjoy discounted electricity at zero cost, with a fixed discount ranging from 20% to 70% off the prevailing electricity tariff rate.",
        icon: "img/services/discountedrate.png"
      },
      {
        title: "Contract Term",
        description: "The PPA contract term ranges from 10 years to 25 years, which is the lifespan of our solar panels.",
        icon: "img/services/contractterm.png"
      },
      {
        title: "System Ownership",
        description: "The Solar PV System is owned by GREENWATT. It is in our interest to ensure the system is always at its maximum efficiency",
        icon: "img/services/ownership.png"
      },
    ]

    return (
      <BaseLayout
        heroContent={
          <h2>Power Purchase Agreement (PPA)</h2>
        }
        heroBackgroundImage="/img/hero/ppa-hero-image.png"
      >
        <Box
        mx={'auto'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '1200px'
        }}
        >
          <Box sx={{ 
              color: "#111", 
              p: 4 
            }}
          >
            {/* About Us Section */}
            <Typography variant="h4" fontWeight="bold" >
              Why PPA?
            </Typography>
            {/* Spacer  */}
            <Box sx={{ mb: 2 }} />

            <Typography variant="body1">
            With GREENWATT's Power Purchase Agreement (PPA), both residential and commercial property owners can utilize solar energy at zero cost.
Under a PPA, GREENWATT fully finances the PV system, handles installation, and provides maintenance for residential and commercial property owners, offering a fixed discount based on the current electricity tariff rate.
            </Typography>
            {/* Spacer  */}
            <Box sx={{ mb: 10 }} />

            {/* Benefits at a glance Section */}
            <Typography variant="h5" fontWeight="bold" textAlign={'center'}>
              Benefits at a glance
            </Typography>

            {/* Spacer  */}
            <Box sx={{ mb: 2 }} />
            {/* Benefits at a glance */}
            <FeatureCardGrid features={BenefitsData}/>   

            {/* Spacer  */}
            <Box sx={{ mb: 10 }} />

            {/* How it works Section */}
            <Typography variant="h5" fontWeight="bold" textAlign={'center'}>
              How it works
            </Typography>
            {/* Spacer  */}
            <Box sx={{ mb: 2 }} />

            <Typography variant="body1">
            The terms of our PPA can vary based on several factors. Some factors include monthly electricity usage (kWh) and rooftop space availability for solar panels.
            </Typography>
            
            {/* Spacer  */}
            <Box sx={{ mb: 2 }} />

            {/* How it Works */}
            <FeatureCardGrid features={HowItWorksData} />            

            {/* Spacer  */}
            <Box sx={{ mb: 10 }} />

            {/* FAQ Section */}
            <Typography variant="h5" fontWeight="bold" textAlign={'center'}>
              FAQs
            </Typography>

            {/* Spacer  */}
            <Box sx={{ mb: 2 }} />

            {/* FAQ Section */}
            <FAQ FAQData={PPAFAQData}/>
          </Box>
        </Box>
      </BaseLayout>
    );
}
