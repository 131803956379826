import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";

const FeatureCardGrid = ({ features }) => {
  return (
    <Grid 
    container 
    spacing={4} 
    textAlign="center"
    justifyContent={ "center"} // Center alignment for single card
    >
      {features.map((feature, index) => (
        <Grid 
        item 
        xs={12} 
        sm={6} 
        md={features.length === 1 ? 6 : 4} 
        lg={features.length === 1 ? 6 : 3} 
        key={index}
        sx={{
          display: 'flex',
          justifyContent: 'stretch'
        }}
        >
          <Card sx={{ backgroundColor: "#F0F0F0", color: "#0D1321", boxShadow: "1px", flexDirection: "column", height: "100%" }}>
            <CardContent sx={{flex: 1}}>
              <img src={feature.icon} style={{ 
                  objectFit: 'contain', // Ensures the image is contained within the space without distortion
                  maxHeight: '80px',
                  marginBlock: '16px'   // Add space below the image
                }}/>
              <Typography variant="h6" fontWeight="bold" mt={1}>
                {feature.title}
              </Typography>
              <Typography variant="body2" mt={1}>
                {feature.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default FeatureCardGrid;
