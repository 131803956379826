
import { useState, useEffect } from "react";
import BaseLayout from "../components/BaseLayout";
import { retrieveProductByCategoryAndProductName } from "../utils/productParser";
import TabView from "../components/TabView/TabView";
import { useParams, Navigate } from "react-router-dom";

export default function ProductDetailsPage() {
    const {productType, productModel} = useParams()
    const [currentTab, setCurrentTab] = useState(null)
    const [tabData, setTabData] = useState(null)

    const createPageView = (data) => {
        let productDetails = data.productDetails
        let tabData = [
            {
                "label": "Key Product Details",
                "content": [
                    {
                        "type": "container",
                        "value": {
                            "orientation": "horizontal",
                            "children": [
                                {
                                    "type": "image",
                                    "value": {
                                        "imagePath": productDetails.productImage,
                                        "caption": "",
                                        "shouldDisplayCaption": false
                                    }
                                },
                                {
                                    "type": "container",
                                    "value": {
                                        "orientation": "vertical",
                                        "children": [
                                            ...Object.entries(productDetails.keyInformation.keySpecifications).map(([key, value], i)=>{
                                                return (
                                                    {
                                                        "type": "labelledText",
                                                        "value": {
                                                            "label": key,
                                                            "content": value,
                                                            "variant": "body1" 
                                                        }
                                                    }
                                                )
                                            })
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        ]
        if (productDetails.additionalInformation != null) {
            tabData.push({
                "label": "Additional Information",
                "content": [
                    {
                        "type": "container",
                        "value": {
                            "orientation": "vertical",
                            "children": [
                                ...productDetails.additionalInformation.additionalProductSpecificationImages.map((model, i)=>{
                                    return ({
                                        "type": "image",
                                        "value": {
                                            "imagePath": model.imagePath,
                                            "caption": model.caption,
                                            "shouldDisplayCaption": model.shouldDisplayCaption || false
                                        }
                                    })
                                })
                            ]
                        }
                    }
                ]
            })
        }
        console.log(tabData)
        setTabData(tabData)
        setCurrentTab(0)
    }

    useEffect(() => {
        let data = retrieveProductByCategoryAndProductName(productType, productModel)
        if (data === undefined || data === null || data.length === 0) {
            return (<Navigate to="/error" />)
        } else {
            createPageView(data)
        }
    }, [])

    // useEffect(() => {
    //     validatePage()
    // }, [productType, productModel])

    // const validatePage = () => {
    //     // SAMPLE DATA:
    //     // {
    //     //     "productTitle": "test jinko title 1",
    //     //     "imagePath": "/img/customize/24kva_cummins.jpg",
    //     //     "specSheetPath": "https://jinkosolarcdn.shwebspace.com/uploads/JKM425-450N-54HL4R-BDB-F4-EN.pdf",
    //     //     "productDetails": {
    //     //         "productImage": "/img/customize/24kva_cummins.jpg",
    //     //         "keyInformation": {
    //     //             "keySpecifications": {
    //     //                 "Dimensions (mm)": "1722 x 1134 x 35/30",
    //     //                 "Cell Size (mm)": "182 x 91",
    //     //                 "Mono TOPCon": "108 CELL",
    //     //                 "Power Output": "420-445Wp",
    //     //                 "Max System Voltage": "1500V DC",
    //     //                 "Max Efficiency": "22.79%"
    //     //             }
    //     //         },
    //     //         "additionalInformation": {
    //     //             "additionalProductSpecificationImages": [
    //     //                 {
    //     //                     "caption": "testAddtionalImage",
    //     //                     "imagePath": "/img/customize/24kva_cummins.jpg",
    //     //                     "shouldDisplayCaption": true
    //     //                 },
    //     //                 {
    //     //                     "caption": "testAddtionalImage2",
    //     //                     "imagePath": "/img/customize/24kva_cummins.jpg",
    //     //                     "shouldDisplayCaption": true
    //     //                 }
    //     //             ]
    //     //         }
    //     //     }
    //     if (productType && productModel) {
            
    //         console.log(currentData)
    //         setData(currentData)
    //         setCurrentTab(0)
    //     }
    // }
    

    return (
        <BaseLayout
        heroContent={
            <>
                <h2>Solar Panels</h2>
                <p>
                    <span>Products</span>
                    <span>&gt;</span>
                    <span>{productType}</span>
                    <span>&gt;</span>
                    <span>{productModel}</span>
                </p>
            </>
        }
            heroBackgroundImage="/img/customize/solarImage.png"
        >
            
        {
        currentTab!=null &&
        <TabView tabData={tabData} currentTab={currentTab} setCurrentTab={setCurrentTab}/>
        }
            
        </BaseLayout>
    );
}

        
        // let data = {
        //     stats: {},
        //     tabs: [
        //         {
        //             label: "Key Information",
        //             content: [
        //                 {
        //                     type: "container",
        //                     value: {
        //                         orientation: "horizontal",
        //                         children: [
        //                             {
        //                                 type: "image",
        //                                 value: "/img/customize/24kva_cummins.jpg",
        //                             },
        //                             {
        //                                 type: "container",
        //                                 value: {
        //                                     orientation: "vertical",
        //                                     children: [
        //                                         {
        //                                             type: "text",
        //                                             value: {
        //                                                 content: "Test Product Name",
        //                                                 variant: "h5"
        //                                             }
        //                                         },
        //                                         {
        //                                             type: "text",
        //                                             value: {
        //                                                 content: "Test Product Description",
        //                                                 variant: "body1"
        //                                             }
        //                                         },
        //                                         {
        //                                             type: "dataTable",
        //                                             value: {
        //                                                 dataFrame: [
        //                                                     {
        //                                                         "Model": "Model 1",
        //                                                         "Dimensions (mm)": "1722 x 1134 x 35/30",
        //                                                         "Cell Size (mm)": "182 x 91",
        //                                                         "Mono TOPCon": "108 Cell",
        //                                                         "Power Output": "420-445Wp",
        //                                                         "Max System Voltage": "1500 V DC"
        //                                                     },
        //                                                     {
        //                                                         "Model": "Model 2",
        //                                                         "Dimensions (mm)": "1722 x 1134 x 35/30",
        //                                                         "Cell Size (mm)": "182 x 91",
        //                                                         "Mono TOPCon": "108 Cell",
        //                                                         "Power Output": "420-445Wp",
        //                                                         "Max System Voltage": "1500 V DC"
        //                                                     },
        //                                                 ]
        //                                             }
        //                                         }
        //                                     ]
        //                                 }
        //                             }
        //                         ]
        //                     }
        //                 },
                        
        //             ]
        //         },
        //         {
        //             label: "Details",
        //             content: [
        //                 {
        //                     type: "image",
        //                     value: "/img/customize/24kva_cummins.jpg",
        //                 }
        //             ]
        //         }
        //     ],
        // }