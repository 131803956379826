import React, { useState } from "react";
import { Typography, AccordionSummary, AccordionDetails, Accordion } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Styled Components
const StyledAccordion = styled((props) => <Accordion {...props} disableGutters elevation={0} />)(
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`, // Add bottom border
    "&:last-of-type": {
      borderBottom: "none", // Remove the last border
    },
    "&:before": {
      display: "none", // Remove default MUI focus underline
    },
  })
);

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0, // Remove extra padding
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.text.secondary, // Adjust expand icon color
  },
  "& .MuiAccordionSummary-content": {
    margin: 0, // Adjust content margin
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: `${theme.spacing(1)} 0`, // Adjust vertical padding
}));

// FAQ Component
const FAQ = ({ FAQData }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {FAQData &&
        FAQData.map((faq, index) => (
          <StyledAccordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <StyledAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="h6" fontWeight={500}>
                {faq.question}
              </Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Typography variant="body1">{faq.answer}</Typography>
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
    </>
  );
};

export default FAQ;
